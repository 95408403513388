<template>
  <v-container>
    <div class="ml-2"><PageTitle class="font" text="Lubes" /></div>
    <div class="buttonText ml-1" id="result">
      <v-btn
        v-ripple="{ class: 'primary--text' }"
        color="primary"
        class="font ml-2 mt-3 white--text font-weight-medium font-size-sm"
        :to="{ name: 'master.lubes.order' }"
      >
        Make Lubes Order <v-icon size="20" color="white">mdi-plus</v-icon>
      </v-btn>

      <v-btn
        :to="{ name: 'master.sales.lubes.today' }"
        color="primary"
        class=" ml-5 mt-3 font font-weight-medium font-size-sm"
      >
        Record Daily Sales<v-icon size="20" color="white" class="ml-2"
          >trending_up</v-icon
        >
      </v-btn>

      <h4
        style="margin-top: 20px; margin-left: 10px"
        class=" font font-weight-medium font-size-md blue-grey--text"
      >
        List of Lubes
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="lubes"
              item-key="product"
              loading-text="Loading... Please wait"
              :loading="loading"
              class="box-shadow-light font"
            >
              <template #item.avatar="{item}">
                <v-avatar
                  color="blue-grey"
                  style="margin-right: -80px"
                  size="25"
                >
                  <span class="white--text subtitle-1 ">{{
                    item.name | firstLetterFunction
                  }}</span>
                </v-avatar>
              </template>
              <template #item.pumpProductId="{item}">
                <v-chip
                  label
                  small
                  color="primary"
                  class="ma-1"
                  v-for="product in item.pumpProductId"
                  :key="product.id"
                >
                  <span class=" font-size-md font-weight-bold">{{
                    product.productId.name
                  }}</span>
                </v-chip>
              </template>
              <template #item.pack="{item}">
                <span v-if="item.lubeId && item.lubeId.length > 0">
                  {{ item.lubeId[0].pack }}
                </span>
              </template>
              <template #item.retailCost="{item}">
                <span v-if="item.lubeId && item.lubeId.length > 0">{{
                  item.lubeId[0].retailCost | currencyFormat
                }}</span>
              </template>
              <template #item.sellPriceToCustomer="{item}">
                <span v-if="item.lubeId && item.lubeId.length > 0">{{
                  item.lubeId[0].sellPriceToCustomers | currencyFormat
                }}</span>
              </template>

              <template #item.retailCostPerUnit="{item}">
                <span v-if="item.lubeId && item.lubeId.length > 0">{{
                  item.lubeId[0].retailCostPerUnit | currencyFormat
                }}</span>
              </template>
              <template #item.createdAt="{item}">
                {{ item.createdAt | timestamp("DD/MM/YYYY") }}
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col></v-row
    >
    <!-- <v-dialog v-model="dialog" width="400px">
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <span class=" font-weight-bold blue-grey--text">Create Pump</span>
            <v-spacer></v-spacer>
            <v-progress-linear
              v-show="progress"
              class="mx-6"
              indeterminate
              color="cyan"
            ></v-progress-linear>
          </v-card-title>
          <v-card-actions>
            <v-spacer
              ><v-form ref="form" class="px-3">
                <v-col>
                  <v-row>
                    <v-text-field
                      outlined
                      label="Name"
                      ref="name"
                      v-model="pump.name"
                      :rules="[rules.requiredName]"
                    >
                    </v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      outlined
                      label="Number Of Nozzles"
                      ref="numberOfNozzles"
                      v-model="pump.numberOfNozzles"
                      :rules="[rules.requiredNozzles]"
                    >
                    </v-text-field>
                  </v-row>
                  <v-row>
                    <v-autocomplete
                      multiple
                      :items="products"
                      small-chips
                      item-text="name"
                      v-model="pump.productId"
                      :rules="[rules.requiredName]"
                      item-value="id"
                      label="Choose products for pump"
                      outlined
                    />
                  </v-row>
                </v-col>
                <v-row>
                  <v-spacer />
                  <v-btn
                    text
                    @click="dialog = false"
                    class="text-capitalize mr-2"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="pump.name === null"
                    color="primary"
                    class=" white--text text-capitalize"
                    @click.stop="add"
                  >
                    Save
                  </v-btn>
                </v-row>
              </v-form></v-spacer
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog> -->

    <!-- <div class="text-center">
      <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
          <v-progress-linear
            v-show="progress"
            indeterminate
            color="cyan"
          ></v-progress-linear>
          <v-card-title class="blue-grey--text">
            <h5>
              Delete this pump?
            </h5>
          </v-card-title>

          <v-card-text>
            <h4>{{ pumpInfo.name }}</h4></v-card-text
          >

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="darken-1" text @click="cancelDelete()">
              No
            </v-btn>

            <v-btn color="red darken-1" text @click="deletePump()">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div> -->

    <!-- <v-dialog v-model="dialogUpdate" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="font-weight-bold blue-grey--text">Update Pump</span>
          <v-spacer></v-spacer>
          <v-progress-linear
            v-show="progress"
            class="mx-6"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-text-field
                    outlined
                    label="Name"
                    ref="name"
                    v-model="itemName"
                    :rules="[rules.requiredName]"
                  >
                  </v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    outlined
                    label="Number Of Nozzles"
                    ref="numberOfNozzles"
                    v-model="pump.numberOfNozzles"
                    :rules="[rules.requiredNozzles]"
                  >
                  </v-text-field>
                </v-row>
                <v-row>
                  <v-autocomplete
                    multiple
                    small-chips
                    :items="products"
                    item-text="name"
                    v-model="pump.productId"
                    :rules="[rules.requiredName]"
                    item-value="id"
                    label="Choose products for pump"
                    outlined
                  />
                </v-row>
                <v-row> </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  class="mr-2 text-capitalize"
                  text
                  @click="dialogUpdate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  class=" white--text text-capitalize"
                  @click.stop="save"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import axios from "axios";
import { timestamp } from "../../filters/timestamp";
import { currencyFormat } from "../../filters/currencyFormat";
export default {
  name: "List",
  components: { PageTitle },
  data() {
    return {
      lubes: [],
      loading: false,
      showSnackBar: false,
      status: "",
      message: ""
    };
  },
  filters: {
    timestamp,
    currencyFormat
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          value: "",
          filterable: false,
          sortable: false
        },
        {
          text: "Name",
          align: "start",
          // sortable: false,
          value: "name"
        },
        {
          text: "Pack",
          value: "pack"
        },
        { text: "Retail Cost", value: "retailCost" },
        { text: "Retail Cost/Unit", value: "retailCostPerUnit" },
        { text: "Selling Price To Customer", value: "sellPriceToCustomer" }
      ];
    }
  },
  methods: {
    async getLubes() {
      try {
        this.loading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE}/products/lubes/list`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.loading = false;

          this.lubes = response.data;
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get lubes list";
        this.status = "red";
        this.loading = false;
      }
    }
  },
  created() {
    this.getLubes();
  }
};
</script>
