var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:"font",attrs:{"text":"Lubes"}})],1),_c('div',{staticClass:"buttonText ml-1",attrs:{"id":"result"}},[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],staticClass:"font ml-2 mt-3 white--text font-weight-medium font-size-sm",attrs:{"color":"primary","to":{ name: 'master.lubes.order' }}},[_vm._v(" Make Lubes Order "),_c('v-icon',{attrs:{"size":"20","color":"white"}},[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:" ml-5 mt-3 font font-weight-medium font-size-sm",attrs:{"to":{ name: 'master.sales.lubes.today' },"color":"primary"}},[_vm._v(" Record Daily Sales"),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"20","color":"white"}},[_vm._v("trending_up")])],1),_c('h4',{staticClass:" font font-weight-medium font-size-md blue-grey--text",staticStyle:{"margin-top":"20px","margin-left":"10px"}},[_vm._v(" List of Lubes ")])],1),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('div',[_c('v-data-table',{staticClass:"box-shadow-light font",attrs:{"headers":_vm.headers,"items":_vm.lubes,"item-key":"product","loading-text":"Loading... Please wait","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"margin-right":"-80px"},attrs:{"color":"blue-grey","size":"25"}},[_c('span',{staticClass:"white--text subtitle-1 "},[_vm._v(_vm._s(_vm._f("firstLetterFunction")(item.name)))])])]}},{key:"item.pumpProductId",fn:function(ref){
var item = ref.item;
return _vm._l((item.pumpProductId),function(product){return _c('v-chip',{key:product.id,staticClass:"ma-1",attrs:{"label":"","small":"","color":"primary"}},[_c('span',{staticClass:" font-size-md font-weight-bold"},[_vm._v(_vm._s(product.productId.name))])])})}},{key:"item.pack",fn:function(ref){
var item = ref.item;
return [(item.lubeId && item.lubeId.length > 0)?_c('span',[_vm._v(" "+_vm._s(item.lubeId[0].pack)+" ")]):_vm._e()]}},{key:"item.retailCost",fn:function(ref){
var item = ref.item;
return [(item.lubeId && item.lubeId.length > 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(item.lubeId[0].retailCost)))]):_vm._e()]}},{key:"item.sellPriceToCustomer",fn:function(ref){
var item = ref.item;
return [(item.lubeId && item.lubeId.length > 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(item.lubeId[0].sellPriceToCustomers)))]):_vm._e()]}},{key:"item.retailCostPerUnit",fn:function(ref){
var item = ref.item;
return [(item.lubeId && item.lubeId.length > 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(item.lubeId[0].retailCostPerUnit)))]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("timestamp")(item.createdAt,"DD/MM/YYYY"))+" ")]}}])})],1)]],2)],1),_c('v-snackbar',{attrs:{"timeout":4000,"top":"","color":_vm.status},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.message))]),_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.showSnackBar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }